import styled from "styled-components";

export const MenuStyles = styled.div`
  .loadingContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .loaderWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
  .fieldArrayWrapper {
    margin-bottom: 60px;
  }
  
  .fieldsWrapper {
    display: grid;
    gap: 24px;
    margin-bottom: 24px;
  }
  
  .fieldArrayItem {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 16px;
    padding-bottom: 8px;
    
    border-bottom: 0.5px solid lightslategrey;
    
    &.last {
      border-bottom: none;
    }
  }
  
  .addButtonContainer {
    width: 200px;
  }
  
  .inputsWrapper {
    display: grid;
    gap: 24px;
    max-width: 400px;
    width: 100%;
    padding: 24px 0;
  }
  
  .addButton {
    display: flex;
    gap: 8px;
    align-items: center;
    
    max-width: 400px;
    width: 100%;
    color: #FFF;
    
    span {
      line-height: 15px;
    }
  }
`;
