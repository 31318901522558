import React, {
  Fragment, useEffect, useMemo, useState,
} from "react";
import { List } from "react-virtualized";
import { Card, Grid } from "@mui/material";
import { HashLoader } from "react-spinners";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { fakeTranslations } from "../../constants/fakeTranslations";
import { TranslationsPageStyles } from "./TranslationsPageStyles";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

const ListRow = React.memo(({ columns, rowData, style }) => (
  <div style={style} className="listWrapper">
    <div className="listColumnsWrapper">
      {columns.map((column) => (
        <div key={column.accessor}>
          {rowData[column.accessor]}
        </div>
      ))}
    </div>
  </div>
));

function TranslationsPage() {
  const [translations, setTranslations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadRows, setReloadRows] = useState(false);

  useEffect(() => {
    setTranslations(fakeTranslations);
    setIsLoading(false);
  }, []);

  const columns = useMemo(() => {
    if (translations) {
      return [
        {
          Header: "Key", accessor: "key", align: "left", width: "300px",
        },
        ...Object.keys(translations).map((lang) => ({
          Header: lang.toUpperCase(),
          accessor: lang,
          align: "center",
          width: "300px",
        })),
      ];
    }

    return [];
  }, [translations]);

  const rows = useMemo(() => {
    if (translations) {
      return Object.keys(translations.uk).map((key) => {
        const rowData = {
          key: (
            <MDTypography
              variant="span"
              fontWeight="medium"
              color="text"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {key.split("_").join(" ")}
            </MDTypography>
          ),
        };

        Object.keys(translations).forEach((lang) => {
          rowData[lang] = (
            <MDInput
              type="text"
              name={`translations.${lang}.${key}`}
              value={translations[lang][key] || ""}
              onChange={(e) => setTranslations((prevTranslations) => ({
                ...prevTranslations,
                [lang]: {
                  ...prevTranslations[lang],
                  [key]: e.target.value,
                },
              }))}
              inputExtraStyles={{ width: "300px" }}
              multiline
              rows={4}
            />
          );
        });

        return rowData;
      });
    }

    return [];
  }, [translations, reloadRows]);

  const handleUploadTranslations = async () => {
    try {
      const file = await new Promise((resolve, reject) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "application/json");

        input.addEventListener("change", (e) => {
          const file = e.target.files[0];
          resolve(file);
        });

        input.click();
      });

      const content = await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          resolve(event.target.result);
        };

        reader.onerror = (event) => {
          reject(event.target.error);
        };

        reader.readAsText(file);
      });

      setTranslations(JSON.parse(content));
    } catch (error) {
      console.error("Error when parsing JSON:", error);
    }
  };

  return (
    <TranslationsPageStyles>
      <DashboardLayout>
        <DashboardNavbar propsRoute={[""]} propsTitle="Translations" />
        <div className="content">
          {!isLoading && translations ? (

            <div className="tableWrapper">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card sx={{ paddingBottom: "20px" }}>
                    <MDBox
                      mx={2}
                      mt={0}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <div className="tableHeader">
                        <MDTypography variant="h6" color="white">
                          Translations
                        </MDTypography>
                      </div>
                    </MDBox>

                    <MDBox pt={3}>
                      <div style={{
                        overflowX: "auto", maxWidth: "100%", paddingLeft: "20px", marginBottom: "40px",
                      }}
                      >
                        <div className="listHead">
                          {columns.map((column) => (
                            <div key={column.accessor}>
                              <MDTypography variant="span" fontWeight="medium" color="text" sx={{ whiteSpace: "pre-wrap" }}>
                                {column.Header}
                              </MDTypography>
                            </div>
                          ))}
                        </div>
                        <List
                          width={(Object.keys(translations).length + 1) * 320}
                          height={620}
                          rowHeight={116}
                          rowCount={rows.length}
                        /* eslint-disable-next-line react/no-unstable-nested-components */
                          rowRenderer={({ index, key, style }) => (
                            <Fragment key={key}>
                              <ListRow columns={columns} rowData={rows[index]} style={style} />
                            </Fragment>
                          )}
                        />
                      </div>

                      <MDBox
                        variant="contained"
                        fontWeight="regular"
                        color="error"
                        sx={{
                          textAlign: "center",
                          fontSize: "18px",
                          marginBottom: "12px",
                          display: "flex",
                          gap: "24px",
                          justifyContent: "center",
                          maxWidth: "500px",
                          margin: "auto",
                        }}
                      >
                        <MDButton
                          color="info"
                          onClick={handleUploadTranslations}
                          sx={{
                            flex: "1 1 0",
                          }}
                        >
                          Upload json
                        </MDButton>

                        <MDButton
                          color="info"
                          onClick={() => {
                          // Add your form submission logic here
                          }}
                          sx={{
                            flex: "1 1 0",
                          }}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="loaderWrapper">
              <HashLoader color="#49a3f1" />
            </div>
          )}
        </div>
      </DashboardLayout>
    </TranslationsPageStyles>
  );
}

export default TranslationsPage;

// // @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
//
// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
//
// // Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//
// // Data
// import DataTable from "../../components/table";
// import React, { useEffect, useMemo, useState } from "react";
// import {
//   Box, Button, Icon, MenuItem, Modal, Typography,
// } from "@mui/material";
// import Menu from "@mui/material/Menu";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import IconButton from "@mui/material/IconButton";
// import Api from "../../api/api";
// import { HashLoader } from "react-spinners";
// import { TranslationsPageStyles } from "./TranslationsPageStyles";
// import { format } from "date-fns";
// import {
//   createSearchParams, Link, useNavigate, useParams, useSearchParams,
// } from "react-router-dom";
// import styles from "./styles";
// import MDButton from "../../components/MDButton";
// import PagesPageFilters from "../../components/pagesPageComponents/pagesPageFilters/PagesPageFilters";
// import { useIsFirstRender } from "usehooks-ts";
// import workWithResponse from "../../functions/workWithResponse";
// import MDInput from "../../components/MDInput";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { List } from "react-virtualized";
// import { fakeTranslations } from "../../constants/fakeTranslations";
//
// function TranslationsPage() {
//   const navigate = useNavigate();
//   const isFirst = useIsFirstRender();
//
//   const [translations, setTranslations] = useState({
//     uk: {
//       title: "qwer",
//       subtitle: "test",
//       testDva: "tes",
//     },
//     en: {
//       title: "qwerty",
//       subtitle: "test",
//       testDva: "test2",
//     },
//     ro: {
//       title: "qwerty",
//       subtitle: "test",
//       testDva: "test2",
//     },
//     it: {
//       title: "qwerty",
//       subtitle: "test",
//       testDva: "test2",
//     },
//     fr: {
//       title: "qwerty",
//       subtitle: "test",
//       testDva: "test2",
//     },
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [activeModalName, setActiveModalName] = useState(null);
//   const [validationSchema, setValidationSchema] = useState(null);
//   const [reloadRows, setReloadRows] = useState(false);
//
//   // useEffect(() => {
//   //   let schema = Yup.object();
//   //
//   //   if (translations) {
//   //     schema = schema.shape({
//   //       translations: Yup.object().shape(
//   //         Object.keys(translations).reduce((langSchema, lang) => {
//   //           const langKeys = Object.keys(translations[lang]);
//   //           const langKeysSchema = langKeys.reduce(
//   //             (keySchema, key) => ({
//   //               ...keySchema,
//   //               [key]: Yup.string().required("This field is required"),
//   //             }),
//   //             {},
//   //           );
//   //
//   //           return {
//   //             ...langSchema,
//   //             [lang]: Yup.object().shape(langKeysSchema),
//   //           };
//   //         }, {}),
//   //       ),
//   //     });
//   //   }
//   //
//   //   setValidationSchema(schema);
//   // }, [translations]);
//
//   // Formik setup
//   const {
//     handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
//   } = useFormik({
//     initialValues: {
//       translations: null,
//     },
//     onSubmit: (values) => {
//       // Add your form submission logic here
//     },
//     validationSchema,
//   });
//
//   useEffect(() => {
//     setTranslations(fakeTranslations);
//     setIsLoading(false);
//   }, []);
//
//   useEffect(() => {
//     if (!isFirst && translations) {
//       setValues({ translations });
//     }
//   }, [translations]);
//
//   useEffect(() => {
//     if (!isFirst && values) {
//       setReloadRows(!reloadRows);
//     }
//   }, [translations]);
//
//   const handleUploadTranslations = async () => {
//     try {
//       const file = await new Promise((resolve, reject) => {
//         const input = document.createElement("input");
//         input.setAttribute("type", "file");
//         input.setAttribute("accept", "application/json");
//
//         input.addEventListener("change", (e) => {
//           const file = e.target.files[0];
//           resolve(file);
//         });
//
//         input.click();
//       });
//
//       const content = await new Promise((resolve, reject) => {
//         const reader = new FileReader();
//
//         reader.onload = (event) => {
//           resolve(event.target.result);
//         };
//
//         reader.onerror = (event) => {
//           reject(event.target.error);
//         };
//
//         reader.readAsText(file);
//       });
//
//       setTranslations(JSON.parse(content));
//     } catch (error) {
//       console.error("Error when parsing JSON:", error);
//     }
//   };
//
//   const columns = useMemo(() => {
//     if (translations) {
//       return (
//         [
//           {
//             Header: "Key", accessor: "key", align: "left", width: "300px",
//           },
//           ...Object.keys(translations).map((lang) => ({
//             Header: lang.toUpperCase(), accessor: lang, align: "center", width: "300px",
//           })),
//         ]
//       );
//     }
//
//     return [];
//   }, [translations]);
//
//   const rows = useMemo(() => {
//     if (translations) {
//       return Object.keys(translations.uk).map((key) => {
//         const rows = {
//           key: (
//             <MDTypography variant="span" fontWeight="medium" color="text" sx={{ whiteSpace: "pre-wrap" }}>
//               {key.split("_").join(" ")}
//             </MDTypography>
//           ),
//         };
//
//         Object.keys(translations).forEach((lang) => {
//           rows[lang] = (
//             <MDInput
//               type="text"
//               name={`translations.${lang}.${key}`}
//               value={values.translations?.[lang]?.[key] || ""}
//               onChange={handleChange}
//               inputExtraStyles={{ width: "300px" }}
//               // error={errors && errors.translations && errors.translations[lang] && errors.translations[lang][key] && touched && touched.translations && touched.translations[lang] && touched.translations[lang][key]}
//               showError
//               multiline
//               rows={4}
//             />
//           );
//         });
//
//         return rows;
//       });
//     }
//
//     return [];
//   }, [translations, isSubmitting, reloadRows]);
//
//   return (
//     <TranslationsPageStyles>
//       {/* {!isLoading && types && languages && <PagesPageFilters types={types} languages={languages} handleClose={() => setFiltersOpened(false)} opened={filtersOpened} onApply={applyFilters} params={searchParams} />} */}
//       <DashboardLayout>
//         <DashboardNavbar propsRoute={[""]} propsTitle="Translations" />
//         <div className="content">
//           {!isLoading && translations ? (
//             <div className="tableWrapper">
//
//               <Grid container spacing={6}>
//                 <Grid item xs={12}>
//                   <Card sx={{ paddingBottom: "20px" }}>
//                     <MDBox
//                       mx={2}
//                       mt={0}
//                       py={3}
//                       px={2}
//                       variant="gradient"
//                       bgColor="info"
//                       borderRadius="lg"
//                       coloredShadow="info"
//                     >
//                       <div className="tableHeader">
//                         <MDTypography variant="h6" color="white">
//                           Translations
//                         </MDTypography>
//                       </div>
//                     </MDBox>
//
//                     <MDBox pt={3}>
//                       {translations && (
//                       // <DataTable
//                       //   table={{ columns, rows }}
//                       //   isSorted={false}
//                       //   entriesPerPage
//                       //   showTotalEntries
//                       //   pagination
//                       //   countOnPage={100}
//                       // />
//                       //
//
//                         <div style={{
//                           overflowX: "auto", maxWidth: "100%", paddingLeft: "20px", marginBottom: "40px",
//                         }}
//                         >
//                           <div className="listHead">
//                             {columns.map((column) => (
//                               <div key={column.accessor}>
//                                 <MDTypography variant="span" fontWeight="medium" color="text" sx={{ whiteSpace: "pre-wrap" }}>
//                                   {column.Header}
//                                 </MDTypography>
//                               </div>
//                             ))}
//                           </div>
//                           <List
//                             width={(Object.keys(translations).length + 1) * 320} // Встановіть бажану ширину таблиці
//                             height={620} // Встановіть бажану висоту таблиці
//                             rowHeight={116} // Встановіть висоту кожного рядка
//                             rowCount={rows.length} // Передайте кількість рядків у масиві даних
//                               /* eslint-disable-next-line react/no-unstable-nested-components */
//                             rowRenderer={({ index, key, style }) => (
//                               <div key={key} style={style} className="listWrapper">
//                                 {/* Тут рендеріть ваш рядок таблиці */}
//                                 {/* Ви можете отримати доступ до даних рядка за допомогою rows[index] */}
//                                 <div className="listColumnsWrapper">
//                                   {columns.map((column, columnIndex) => (
//                                     // eslint-disable-next-line react/no-array-index-key
//                                     <div key={columnIndex}>
//                                       {/* Рендеріть комірку таблиці */}
//                                       {/* Ви можете отримати доступ до даних комірки за допомогою rows[index][column.accessor] */}
//                                       {rows[index][column.accessor]}
//                                     </div>
//                                   ))}
//                                 </div>
//                               </div>
//                             )}
//                           />
//                         </div>
//                       )}
//
//                       <MDBox
//                         variant="contained"
//                         fontWeight="regular"
//                         color="error"
//                         sx={{
//                           textAlign: "center", fontSize: "18px", marginBottom: "12px", display: "flex", gap: "24px", justifyContent: "center", maxWidth: "500px", margin: "auto",
//                         }}
//                       >
//                         <MDButton
//                           color="info"
//                           onClick={handleUploadTranslations}
//                           sx={{
//                             flex: "1 1 0",
//                           }}
//                         >
//                           Upload json
//                         </MDButton>
//
//                         <MDButton
//                           color="info"
//                           onClick={handleSubmit}
//                           sx={{
//                             flex: "1 1 0",
//                           }}
//                         >
//                           Submit
//                         </MDButton>
//                       </MDBox>
//                     </MDBox>
//                   </Card>
//                 </Grid>
//               </Grid>
//             </div>
//           ) : (
//             <div className="loaderWrapper">
//               <HashLoader color="#49a3f1" />
//             </div>
//           )}
//         </div>
//       </DashboardLayout>
//     </TranslationsPageStyles>
//   );
// }
//
// export default TranslationsPage;
