import styled from "styled-components";

export const DashboardStyles = styled.div`
  .content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: flex-start;
    gap: 20px;
    padding: 30px 1rem 0;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    
    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
    
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      flex: 1 1 0px;
      
      padding: 50px 15px;
      height: fit-content;

      color: #ffffff;
      background: linear-gradient(195deg, #42424a, #191919);
      border-radius: 4px;
      box-sizing: border-box;
      transition: 0.3s ease all;

      &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      }
      
      span {
        font-size: 40px !important;
      }
    }
  }
`;
