import styled from "styled-components";

export const MultipleSelectStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .selectWrapper {
    position: relative;

    display: grid;
    gap: 4px;
    flex-grow: 1;

    .MuiTypography-root {
      position: absolute;
      bottom: -18px;
    }
  }

  .errorMessage {
    position: absolute;
    bottom: -4px;
  }

  .formErrorContainer {
    width: 100%;
    height: 12px;
  }
`;
