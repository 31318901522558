import styled from "styled-components";

export const TranslationsPageStyles = styled.div`
  .loaderWrapper {
    position: absolute;
    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
  .tableWrapper {
    overflow-x: hidden;
    width: 100%;
    
    table {
      width: fit-content;
    }
    
    .listWrapper {
      overflow: auto;
    }
    
    .listColumnsWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      gap: 10px;
    }
    
    .listHead {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
      
      div {
        min-width: 300px;
        text-align: center;
      }
    }
  }
  
  .content {
    position: relative;
    display: flex;
    padding-top: 30px;
    height: 100%;
    flex-grow: 1;
  }
  
  .tableWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  
  .paginationButtonWrapper {
    display: flex;
    justify-content: center;
  }
  
  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .tableHeaderButtonsWrapper {
      display: flex;
      gap: 16px;
      
      .filtersButtonIcon {
        color: #FFF;
      }
    }
    
    .button {
      display: flex;
      align-items: center;
      gap: 4px;
      
      border: 1px solid #FFF;
      padding: 8px 6px;
      border-radius: 8px;
      
      path {
        color: #FFF;
      }
    }
  }
`;
