// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import DataTable from "../../../components/table";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box, Button, MenuItem, Modal, Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Api from "../../../api/api";
import { HashLoader } from "react-spinners";
import {
  createSearchParams, Link, useNavigate, useParams, useSearchParams,
} from "react-router-dom";
import MDButton from "../../../components/MDButton";
import { useIsFirstRender } from "usehooks-ts";
import workWithResponse from "../../../functions/workWithResponse";
import { HashtagsTableStyles } from "./HashtagsTableStyles";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gap: "24px",
};

const RowMenu = ({ id, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to={`/hashtags/edit/${id}`} style={{ color: "#7b809a", width: "100%", height: "100%" }}>
            Edit
          </Link>
        </MenuItem>
        <MenuItem onClick={() => {
          handleDelete(id);
          handleClose();
        }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

function HashtagsTable() {
  const { pageNumber } = useParams();
  const navigate = useNavigate();
  const isFirst = useIsFirstRender();

  const [hashtagsData, setHashtags] = useState();
  const [types, setTypes] = useState();
  const [languages, setLanguages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hashtagsCount, setHashtagsCount] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [activeModalName, setActiveModalName] = useState(null);
  const [pageToDeleteId, setPageToDeleteId] = useState(null);
  const [deletingError, setDeletingError] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  console.log(123);

  useEffect(() => {
    setActivePage(+pageNumber);
  }, []);

  async function getHashtagsData() {
    const { data: hashtags, count } = await workWithResponse(() => Api.getHashtags((activePage - 1) * 10, 10)).then((res) => {
      if (!res.error && res.data) {
        return res.data;
      }
    });

    if (count !== hashtagsCount) {
      setHashtagsCount(count);
    }

    setHashtags(hashtags);
  }

  useEffect(() => {
    if (!isFirst) {
      setIsLoading(true);

      const params = createSearchParams(searchParams).toString();

      if (activePage !== pageNumber) {
        navigate(`/hashtags/${activePage}`);
      }

      getHashtagsData().then(() => setIsLoading(false));
    }
  }, [activePage, searchParams]);

  useEffect(() => {
    async function getInitialData() {
      const languagesFromServer = await workWithResponse(() => Api.getLanguages()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      const typesFromServer = await workWithResponse(() => Api.getTypes()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      setLanguages(languagesFromServer);
      setTypes(typesFromServer);

      const hashtagsData = await workWithResponse(() => Api.getHashtags()).then((res) => {
        if (!res.error && res.data) {
          setHashtagsCount(res.data.count);

          return res.data.data;
        }
      });

      setHashtags(hashtagsData);
    }

    getInitialData().then(() => setIsLoading(false));
  }, []);

  const columns = useMemo(() => (
    [
      {
        Header: "Name", accessor: "name", width: "30%", align: "left",
      },
      { Header: "Attached languages", accessor: "attachedLanguages", align: "center" },
      { Header: "Attached types", accessor: "attachedTypes", align: "center" },
      // { Header: "Create at", accessor: "createdAt", align: "center" },
      // { Header: "Update at", accessor: "updatedAt", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]
  ), []);

  function handleDeletingPageMetaData(id) {
    if (deletingError) {
      setDeletingError(false);
    }

    setActiveModalName("deleting");
    setPageToDeleteId(id);
  }

  const rows = useMemo(() => {
    if (hashtagsData) {
      return (
        hashtagsData.map((hashtag) => (
          {
            name: (
              <MDTypography component="span" variant="button" color="text" fontWeight="medium">
                {hashtag.name}
              </MDTypography>
            ),
            attachedLanguages: (
              <Box display="flex" gap="4px" flexDirection="column">
                {hashtag.attachedLanguages.map((attachedLanguageId) => (
                  <MDTypography component="span" variant="button" color="text" fontWeight="medium">
                    {languages.find((language) => attachedLanguageId === language.id)?.name}
                  </MDTypography>
                ))}
              </Box>
            ),
            attachedTypes: (
              <Box display="flex" gap="4px" flexDirection="column">
                {hashtag.attachedTypes.map((attachedTypeId) => (
                  <MDTypography component="span" variant="button" color="text" fontWeight="medium">
                    {types.find((type) => attachedTypeId === type.id)?.name}
                  </MDTypography>
                ))}
              </Box>
            ),
            // createdAt: format(page.createdAt, "kk:mm:ss dd.MM.yyyy"),
            // updatedAt: format(page.updatedAt, "kk:mm:ss dd.MM.yyyy"),
            // TODO: uncomment createAt and updateAt
            // createdAt: format(new Date(page.createdAt), "kk:mm:ss dd.MM.yyyy"),
            // updatedAt: format(new Date(page.updatedAt), "kk:mm:ss dd.MM.yyyy"),
            action: (
              <RowMenu id={hashtag.id} handleDelete={handleDeletingPageMetaData} />
            ),
          }
        ))
      );
    }
  }, [hashtagsData]);

  return (
    <HashtagsTableStyles>
      {/* {!isLoading && types && languages && <PagesPageFilters types={types} languages={languages} handleClose={() => setFiltersOpened(false)} opened={filtersOpened} onApply={applyFilters} params={searchParams} />} */}
      <DashboardLayout>
        {activeModalName === "deleting" && (
          <Modal
            open={activeModalName === "deleting"}
            onClose={() => setActiveModalName(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm deleting
                {" "}
                {hashtagsData.find((page) => page.id === pageToDeleteId).name}
                {" "}
                hashtag.
              </Typography>

              <Box display="grid" sx={{ gap: "10px" }}>
                <Grid gridColumn={1}>
                  <MDButton fullWidth onClick={() => setActiveModalName(null)} variant="contained" color="info">
                    Cancel
                  </MDButton>
                </Grid>
                <Grid gridColumn={2}>
                  <MDButton
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => {
                      workWithResponse(() => Api.deleteHashtag(pageToDeleteId)).then((res) => {
                        if (res.data && !res.error) {
                          getHashtagsData();
                          setActiveModalName(null);
                        }
                      });
                    }}
                  >
                    Delete
                  </MDButton>
                </Grid>
              </Box>

              {deletingError && (
                <MDTypography component="span" href="#" variant="caption" color="error" fontWeight="medium">
                  *An error occurred while deleting
                </MDTypography>
              )}
            </Box>
          </Modal>
        )}

        <DashboardNavbar propsRoute={[""]} propsTitle="Hashtags data" />
        <div className="content">
          {!isLoading && hashtagsData ? (
            <div className="tableWrapper">

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <div className="tableHeader">
                        <MDTypography variant="h6" color="white">
                          Hashtags data
                        </MDTypography>

                        <div className="tableHeaderButtonsWrapper">
                          {/* <IconButton onClick={() => setFiltersOpened(true)}> */}
                          {/*  <Icon className="filtersButtonIcon">tune</Icon> */}
                          {/* </IconButton> */}

                          <Link to="/hashtags/create">
                            <Button variant="contained" color="info">
                              <MDTypography variant="button" color="info" fontWeight="bold">
                                Add new
                              </MDTypography>
                            </Button>
                          </Link>
                        </div>

                        {/* <Link to="/pages/create" className="button"> */}
                        {/* <MDTypography variant="Button" color="white" fontWeight="regular"> */}
                        {/*  Add new */}
                        {/* </MDTypography> */}

                        {/*  <AddCircleOutlineIcon /> */}
                        {/* </Link> */}
                      </div>

                    </MDBox>

                    <MDBox pt={3}>
                      {hashtagsCount > 0 ? (
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage
                          showTotalEntries
                          noEndBorder
                          pagination
                          activePage={activePage}
                          pagesCount={hashtagsCount}
                          setActivePage={setActivePage}
                          countOnPage={10}
                        />
                      ) : (
                        <MDBox variant="" fontWeight="regular" color="error" sx={{ textAlign: "center", fontSize: "18px", marginBottom: "12px" }}>
                          No hashtags data were found for your request
                        </MDBox>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="loaderWrapper">
              <HashLoader color="#49a3f1" />
            </div>
          )}
        </div>
      </DashboardLayout>
    </HashtagsTableStyles>
  );
}

export default HashtagsTable;
