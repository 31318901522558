import React, { useEffect, useState } from "react";
import { HashtagStyles } from "./HashtagStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import workWithResponse from "../../../functions/workWithResponse";
import Api from "../../../api/api";
import * as Yup from "yup";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { Box, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import Button from "@mui/material/Button";
import { HashLoader } from "react-spinners";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MultipleSelect from "../../../components/form/multipleSelect/MultipleSelect";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gap: "24px",
};

function Hashtag() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [languages, setLanguages] = useState(null);
  const [types, setTypes] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [submittingError, setSubmittingError] = useState(false);
  const [afterSaveModalOpen, setAfterSaveModalOpen] = useState(false);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: null,
    onSubmit: (values) => {

    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required field"),
    }),
  });

  useEffect(() => {
    setIsLoading(true);

    async function getInitialData() {
      const languagesFromServer = await workWithResponse(() => Api.getLanguages()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      const typesFromServer = await workWithResponse(() => Api.getTypes()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      setLanguages(languagesFromServer);
      setTypes(typesFromServer);

      if (id) {
        const hashtagFromServer = await workWithResponse(() => Api.getHashtag(id)).then((res) => {
          if (!res.error && res.data) {
            return res.data;
          }
        });

        setValues(
          {
            name: hashtagFromServer.name,
            attachedLanguages: hashtagFromServer.attachedLanguages,
            attachedTypes: hashtagFromServer.attachedTypes,
          },
        );
      } else {
        setValues(
          {
            name: "",
            attachedLanguages: [],
            attachedTypes: [],
          },
        );
      }
    }

    getInitialData().then(() => setIsLoading(false));
  }, []);

  return (
    <HashtagStyles>
      <DashboardLayout>
        <DashboardNavbar propsRoute={[{ name: "pages meta", path: "pages/1" }, ""]} propsTitle={id && values && values.name ? `Editing ${values.name} hashtag` : "New hashtag"} />

        {afterSaveModalOpen && (
          <Modal
            open={afterSaveModalOpen}
            onClose={() => setAfterSaveModalOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Hashtag was succefully
                {" "}
                {id ? "updated" : "created"}
              </Typography>

              <Box display="grid" sx={{ gap: "10px" }}>
                <Grid gridColumn={1}>
                  <MDButton fullWidth onClick={() => navigate("/meta")} variant="contained" color="info">
                    OK
                  </MDButton>
                </Grid>
              </Box>
            </Box>
          </Modal>
        )}

        {!isLoading && values ? (
          <div className="content">
            <div className="formContent">
              <div className="fields">
                <MDInput
                  name="name"
                  type="text"
                  label="Name"
                  showError
                  value={values.name}
                  error={errors.name && touched.name}
                  errorMessage={errors.name}
                  success={values.name.length > 0}
                  onChange={handleChange}
                  inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                />
              </div>

              <div className="fields">
                <MultipleSelect
                  values={values.attachedLanguages}
                  name="attachedLanguages"
                  error={false}
                  optionTextKey="name"
                  valueKey="id"
                  errorMessage=""
                  options={languages}
                  onChange={(e) => setFieldValue("attachedLanguages", e.target.value)}
                  label="Attached languages"
                  withSearch={false}
                />

                <MultipleSelect
                  values={values.attachedTypes}
                  name="attachedTypes"
                  error={false}
                  optionTextKey="name"
                  valueKey="id"
                  errorMessage=""
                  options={types}
                  onChange={(e) => setFieldValue("attachedTypes", e.target.value)}
                  label="Attached types"
                  withSearch={false}
                />
              </div>

              <div className="submitButtonContainer">
                <Button onClick={handleSubmit} type="button" variant="contained" color={submittingError ? "error" : "primary"} sx={{ width: 400, margin: "auto", color: "#FFF" }}>Submit</Button>
                {submittingError && (
                  <MDTypography variant="caption" fontWeight="regular" color="error">
                    An error occurred while saving data
                  </MDTypography>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="loaderWrapper">
            <HashLoader color="#49a3f1" />
          </div>
        )}
      </DashboardLayout>
    </HashtagStyles>
  );
}

export default Hashtag;
