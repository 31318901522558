import {
  fakeHashtagData, fakeHashtagsData,
  fakeMenuData, fakePageMeta, fakePagesMeta, fakeTypesData,
} from "../constants/fakeData";
import { mapFromFileToFormData } from "./mappers";

export default class Api {
  // eslint-disable-next-line class-methods-use-this
  static baseUrl = process.env.REACT_APP_API_HOST;

  static async fetchData(path, requestOptions) {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });

      const statusCode = response.status;
      const data = await response.json();

      return {
        data,
        statusCode,
      };
    } catch (e) {
      console.log("FETCH ERROR:", e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  static async getData(path, tokenRequired = true) {
    const myHeaders = {};

    if (tokenRequired) {
      const accessToken = path === "/refresh" ? localStorage.getItem("refresh_token") : localStorage.getItem("access_token");
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      mode: "cors",
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  static async postData(path, data, formData, tokenRequired = true) {
    const myHeaders = {};
    if (!formData) {
      myHeaders["Content-Type"] = "application/json";
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem("access_token");
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: "follow",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrerPolicy: "no-referrer",
    };

    return this.fetchData(path, requestOptions);
  }

  static async putData(path, data, formData) {
    const accessToken = localStorage.getItem("access_token");
    const myHeaders = {};
    if (!formData) {
      myHeaders["Content-Type"] = "application/json";
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: "follow",
    };

    return this.fetchData(path, requestOptions);
  }

  static async deleteData(path, data) {
    const accessToken = localStorage.getItem("access_token");
    const myHeaders = {};

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(data),
    };

    return this.fetchData(path, requestOptions);
  }

  static async sendData(data) {
    return this.postData("", data);
  }

  // eslint-disable-next-line class-methods-use-this
  static async getTypes() {
    return Promise.resolve({ data: fakeTypesData, statusCode: 200 });
    // return this.getData("/types");
  }

  // eslint-disable-next-line class-methods-use-this
  static async getPages(offset, limit, params) {
    const path = `/articles/?offset=${offset || 0}&limit=${limit || 10}`;

    // return Promise.resolve({ data: { data: fakePagesData, count: 79 }, statusCode: 200 });

    return this.getData(path);
  }

  // eslint-disable-next-line class-methods-use-this
  static async deletePage(id) {
    // return Promise.resolve({ data: { message: "OK" }, statusCode: 200 });
    // return Promise.reject();

    return this.deleteData(`/articles/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  static async getPage(id) {
    // return Promise.resolve({ data: fakePageData, statusCode: 200 });

    return this.getData(`/articles/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  static async createPage(data) {
    // return Promise.resolve({ data: fakePageData, statusCode: 200 });

    return this.postData("/articles/", data);
  }

  // eslint-disable-next-line class-methods-use-this
  static async updatePage(id, data) {
    // return Promise.resolve({ data: fakePageData, statusCode: 200 });

    return this.putData(`/articles/${id}`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  static async getLanguages() {
    // return Promise.resolve({ data: fakeLanguages, statusCode: 200 });

    return this.getData("/languages/");
  }

  static async getPagesMainInfo() {
    // return Promise.resolve({ data: fakePagesMainInfo, statusCode: 200 });

    return this.getData("/types/");
  }

  static async getPagesMeta(offset, limit, params) {
    const path = `/meta_data/?offset=${offset || 0}&limit=${limit || 10}`;

    // return Promise.resolve({ data: { data: fakePagesMeta, count: 2 }, statusCode: 200 });

    return this.getData(path);
  }

  static async getPageMetaData(id) {
    const path = `/meta_data/${id}`;

    // return Promise.resolve({ data: fakePageMeta, statusCode: 200 });

    return this.getData(path);
  }

  static async createPageMetaData(id, values) {
    const path = `/meta_data/${id ?? ""}`;

    // return Promise.resolve({ data: fakePageMeta, statusCode: 200 });

    return this.postData(path, values);
  }

  static async updatePageMetaData(id, values) {
    const path = `/meta_data/${id}`;

    // return Promise.resolve({ data: fakePageMeta, statusCode: 200 });

    return this.putData(path, values);
  }

  static async deletePageMetaData(id) {
    const path = `/meta_data/${id}`;

    // return Promise.resolve({ data: fakePageMeta, statusCode: 200 });

    return this.deleteData(path);
  }

  static async getHashtag(id) {
    return Promise.resolve({ data: fakeHashtagData, statusCode: 200 });

    // return this.getData(`/hashtags/${id}`);
  }

  static async deleteHashtag(id) {
    return Promise.resolve({ data: fakeMenuData, statusCode: 200 });

    // return this.deleteData(`/hashtags/${id}`);
  }

  static async updateHashtag(id) {
    return Promise.resolve({ data: fakeMenuData, statusCode: 200 });

    // return this.putData(`/hashtags/${id}`);
  }

  static async getHashtags(limit = 10, offset = 0) {
    return Promise.resolve({ data: { data: fakeHashtagsData, count: fakeHashtagsData.length }, statusCode: 200 });

    // return this.getData(`/hashtags?limit=${limit}&offset=${offset}`);
  }

  static async getMenuData() {
    return Promise.resolve({ data: fakeMenuData, statusCode: 200 });

    // return this.getData("/menu");
  }

  // eslint-disable-next-line class-methods-use-this
  static async login(data) {
    // const response: ApiResponse<ApiAuthResponse> = await this.postData('/login', data);
    // console.log(response);
    // return response;
    return this.postData("/login", data, false);
  }

  // eslint-disable-next-line class-methods-use-this
  static async logout() {
    // const response: ApiResponse<ApiAuthResponse> = await this.postData('/login', data);
    // console.log(response);
    // return response;
    return this.getData("/logout");
  }

  // eslint-disable-next-line class-methods-use-this
  static async refresh() {
    // const response: ApiResponse<ApiAuthResponse> = await this.postData('/login', data);
    // console.log(response);
    // return response;
    return this.getData("/refresh");
  }

  static async createFile(data) {
    const formDataFromData = mapFromFileToFormData(data.file);

    return this.postData("/files/", formDataFromData, true);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  static async getFile(id) {
    return this.getData(`/files/${id}`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  static async updateFile(id, data) {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.putData(`/files/${id}`, formDataFromData, true);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  static async deleteFile(id) {
    return this.deleteData(`/files/${id}`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }
}
