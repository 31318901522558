/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";

// react-router components
import {
  Navigate, Route, Routes, useLocation,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";

// Images
import Sidenav from "./components/Sidenav";
import Page from "./pages/page/Page";
import Login from "./pages/login/Login";
import { useAppDispatch, useAppSelector } from "./state/hooks";
import Api from "./api/api";
import { changeUser } from "./state/slices/userSlice";
import TranslationsPage from "./pages/translationsPage/TranslationsPage";
import PageMeta from "./pages/pagesMeta/pageMeta/PageMeta";
import PagesMetaTable from "./pages/pagesMeta/PagesMetaTable";
import Hashtag from "./pages/hashtags/hashtag/Hashtag";
import Dashboard from "./pages/dashboard/Dashboard";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav, direction, layout, openConfigurator, sidenavColor, darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { loggedIn } = useAppSelector((state) => state.user);
  const reduxDispatch = useAppDispatch();

  useEffect(() => {
    function checkIsUserLoggedIn() {
      const status = localStorage.getItem("logged_in");

      if (status === "false" && loggedIn === true) {
        reduxDispatch(changeUser(null));
      }
    }

    if (localStorage.getItem("logged_in") === "false" && loggedIn === true) {
      reduxDispatch(changeUser(null));
    }

    window.addEventListener("logged_in", checkIsUserLoggedIn);
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="Admin-panel"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}
      {/* {layout === "vr" && <Configurator />} */}
      <Routes>
        {!loggedIn ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            {getRoutes(routes)}
            <Route path="/" element={<Dashboard />} />
            <Route path="/pages/edit/:id" element={<Page />} />
            <Route path="/pages/create" element={<Page />} />
            <Route path="/meta" element={<PagesMetaTable />} />
            <Route path="/meta/create" element={<PageMeta />} />
            <Route path="/meta/edit/:id" element={<PageMeta />} />
            {/* <Route path="/translations" element={<TranslationsPage />} /> */}
            {/* <Route path="/hashtags/create" element={<Hashtag />} /> */}
            {/* <Route path="/hashtags/edit/:id" element={<Hashtag />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </ThemeProvider>
  );
}
