import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useFormikContext } from "formik";
import { TextEditorStyles } from "./TextEditorStyles";
// eslint-disable-next-line import/no-webpack-loader-syntax
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
// eslint-disable-next-line
import "tinymce/skins/ui/oxide/skin.min.css";
import Api from "../../../api/api";
import MDTypography from "../../MDTypography";

export default function TextEditor({
  name,
  placeholder,
  errorMessage,
  label,
  error,
  showError = true,
  value,
  onChange,
  onKeyUp,
  extraBlockStyles,
  required,
  withMenuBar,
  toolbarItems,
  height,
}) {
  const { touched } = useFormikContext();

  // const handleEditorChange = (content: any, editor: any) => {
  //   console.log('Content was updated:', content);
  // };

  // TODO: add lang localization

  // const editorRef = useRef(null);
  // const editorRef = useRef(null);

  const handleEditorInit = (e, editor) => {
    editor.ui.registry.getAll().menuItems.fontfamily = null;
  };

  return (
    <TextEditorStyles className={error ? "error" : ""}>
      <div style={extraBlockStyles}>
        <label htmlFor={name}>
          {label
            && (
              <span>
                {label}
                {required && <span className="required"> *</span>}
              </span>
            )}
          <div className="inputWrap">
            <div className="inputContainer">
              <Editor
                apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
                value={value}
                textareaName={name}
                // onDrop={async (event, ...props) => {
                //   // Stops the drag and drop pop up
                //
                //   event.preventDefault();
                //   event.stopImmediatePropagation();
                //   event.stopPropagation();
                //
                //   (console.log(props));
                //
                //   const file = event.dataTransfer.files;
                //
                //   // const uploadedFiles = await this.uploadFiles(files); // backend call
                //
                //   // uploadedFiles.forEach((file) => {
                //   //   // modify this for different file types
                //   //   this.insertContent(`<img src="${file.Url}" />`);
                //   // });
                // }}
                init={{
                  // init_instance_callback(editor) {
                  //   editor.on("Drop", handleDrop);
                  // },
                  extended_valid_elements: "video[controls|src]", // Визначаємо власні валідні атрибути для відео
                  setup: (editor) => {
                    editor.on("BeforeSetAttrib", (e) => {
                      if (e.meta.name === "video" && (e.meta.name === "width" || e.meta.name === "height")) {
                        e.meta.remove();
                      }
                    });
                  },
                  content_style: [contentCss, contentUiCss, "video { width: 100%; height: auto; }", "img { max-width: 100%; width: 100%; height: auto; }", "table {table-layout: fixed; width: 100%;} td {word-break: break-all;}"].join("\n"),
                  // images_upload_url: "postAcceptor.php",
                  file_picker_callback: async (cb, value, meta) => {
                    if (meta.filetype === "image") {
                      const input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "image/*");

                      input.addEventListener("change", async (e) => {
                        const file = e.target.files[0];

                        const uploadedFile = await Api.createFile({ file }).then((res) => {
                          if (res.statusCode >= 200 && res.statusCode < 300) {
                            return res.data;
                          }
                        });

                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                          /*
                            Note: Now we need to register the blob in TinyMCEs image blob
                            registry. In the next release this part hopefully won't be
                            necessary, as we are looking to handle it internally.
                          */
                          const id = `blobid${(new Date()).getTime()}`;
                          const base64 = reader.result.split(",")[1];

                          /* call the callback and populate the Title field with the file name */
                          cb(uploadedFile.url, { title: uploadedFile.name });
                        });
                        reader.readAsDataURL(file);
                      });

                      input.click();
                    } else if (meta.filetype === "media") {
                      const input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "video/*");

                      input.addEventListener("change", async (e) => {
                        const file = e.target.files[0];

                        const uploadedFile = await Api.createFile({ file }).then((res) => {
                          if (res.statusCode >= 200 && res.statusCode < 300) {
                            return res.data;
                          }
                        });

                        /* call the callback and populate the Title field with the file name */
                        cb(uploadedFile.url, { title: uploadedFile.name });
                      });

                      input.click();
                    } else if (meta.filetype === "file") {
                      const input = document.createElement("input");
                      input.setAttribute("type", "file");

                      input.addEventListener("change", async (e) => {
                        const file = e.target.files[0];

                        const uploadedFile = await Api.createFile({ file }).then((res) => {
                          if (res.statusCode >= 200 && res.statusCode < 300) {
                            return res.data;
                          }
                        });

                        /* call the callback and populate the Title field with the file name */
                        cb(uploadedFile.url, { title: uploadedFile.name });
                      });

                      input.click();
                    }
                  },
                  image_title: true,
                  // width: "100%",
                  file_picker_types: "image,media,file",
                  images_file_types: "jpg,svg,png,webp",
                  paste_data_images: false,
                  block_unsupported_drop: false,
                  paste_block_drop: false,
                  skin: false,
                  content_css: false,
                  object_resizing: "table",
                  image_dimensions: false,
                  media_dimensions: false,
                  language: "en",
                  placeholder,
                  height: height || 700,
                  statusbar: false,
                  automatic_uploads: false,
                  plugins: [
                    "advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor",
                    "searchreplace", "visualblocks", "code", "fullscreen",
                    "insertdatetime", "media", "table", "code", "help", "wordcount", "emoticons", "paste",
                  ],
                  textcolor_rows: "2",
                  toolbar:
                    toolbarItems || [
                      "undo redo |"
                      + " bold italic underline strikethrough forecolor backcolor emoticons |"
                      + " outdent indent | blockquote subscript superscript |"
                      + " blocks fontsize numlist bullist alignleft aligncenter alignright |"
                      + " image media link insertfile | "
                      + "code",
                    ],
                  font_size_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 36px 48px",
                  menubar: "format table tools",
                }}
                onEditorChange={(stringifiedHtmlValue) => {
                  onChange(name, stringifiedHtmlValue);
                }}
                onInit={handleEditorInit}
                // onNodeChange={(e) => {
                //   if (e && e.element.nodeName.toLowerCase() === "img") {
                //     editorRef.current.dom.setAttribs(e.element, {
                //       width: "100%",
                //       height: "auto",
                //     });
                //   }
                // }}
                // @ts-ignore
                outputFormat="html"
              />
              {error && errorMessage && (
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {errorMessage}
                </MDTypography>
              )}
            </div>
          </div>
        </label>
      </div>
    </TextEditorStyles>
  );
}
