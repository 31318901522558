// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import DataTable from "../../components/table";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box, Button, Icon, MenuItem, Modal, Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Api from "../../api/api";
import { HashLoader } from "react-spinners";
import { PagesPageStyles } from "./PagesPageStyles";
import { format } from "date-fns";
import {
  createSearchParams, Link, useNavigate, useParams, useSearchParams,
} from "react-router-dom";
import styles from "./styles";
import MDButton from "../../components/MDButton";
import PagesPageFilters from "../../components/pagesPageComponents/pagesPageFilters/PagesPageFilters";
import { useIsFirstRender } from "usehooks-ts";
import workWithResponse from "../../functions/workWithResponse";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gap: "24px",
};

const RowMenu = ({ id, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link to={`/pages/edit/${id}`} style={{ color: "#7b809a", width: "100%", height: "100%" }}>
            Edit
          </Link>
        </MenuItem>
        <MenuItem onClick={() => {
          handleDelete(id);
          handleClose();
        }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

function PagesPage() {
  const { pageNumber } = useParams();
  const navigate = useNavigate();
  const isFirst = useIsFirstRender();

  const [pagesData, setpagesData] = useState();
  const [types, setTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pagesCount, setPagesCount] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [activeModalName, setActiveModalName] = useState(null);
  const [pageToDeleteId, setPageToDeleteId] = useState(null);
  const [deletingError, setDeletingError] = useState(true);
  const [filtersOpened, setFiltersOpened] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // const newParams = createSearchParams(searchParams);
    // newParams.set("id", "1");
    // setSearchParams(newParams);

    setActivePage(+pageNumber);
  }, []);

  function getSearchParamsForApiQueryFromPathSearchParams(params) {
    const result = [];

    for (const [key, value] of params.entries()) {
      result.push(`${key}=${value}`);
    }

    return result.length > 0 ? result.join("&") : null;
  }

  async function getPages() {
    const { data: pages, count } = await workWithResponse(() => Api.getPages((activePage - 1) * 10, 10, getSearchParamsForApiQueryFromPathSearchParams(searchParams))).then((res) => {
      if (!res.error && res.data) {
        return res.data;
      }
    });

    const pagesWithTypesAndLanguages = pages.map((page) => {
      const language = languages.find((language) => language.id === page.languageID);
      const type = types.find((type) => type.id === page.type);

      return (
        {
          ...page,
          language: language.name.charAt(0).toUpperCase() + language.name.slice(1),
          type: type.name,
        }
      );
    });

    if (count !== pagesCount) {
      setPagesCount(count);
    }

    setpagesData(pagesWithTypesAndLanguages);
  }

  useEffect(() => {
    if (!isFirst) {
      setIsLoading(true);

      const params = createSearchParams(searchParams).toString();

      if (activePage !== pageNumber) {
        navigate(`/pages/${activePage}?${params}`);
      }

      getPages().then(() => setIsLoading(false));
    }
  }, [activePage, searchParams]);

  useEffect(() => {
    async function getInitialData() {
      const types = await workWithResponse(() => Api.getTypes()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      // workWithResponse(() => Api.getLanguages());

      const languages = await workWithResponse(() => Api.getLanguages()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      const { data: pages, count } = await workWithResponse(() => Api.getPages((activePage - 1) * 10, 10, getSearchParamsForApiQueryFromPathSearchParams(searchParams))).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      // const data = await Api.getPages((activePage - 1) * 10, 10, getSearchParamsForApiQueryFromPathSearchParams(searchParams)).then((res) => {
      //   if (res.statusCode >= 200 && res.statusCode < 300) {
      //     return res.data;
      //   }
      // });

      const pagesWithTypesAndLanguages = pages.map((page) => {
        const language = languages.find((language) => language.id === page.languageID);
        const type = types.find((type) => type.id === page.type);

        return (
          {
            ...page,
            language: language.name.charAt(0).toUpperCase() + language.name.slice(1),
            type: type.name,
          }
        );
      });
      // const pagesWithTypesAndLanguages = data.map((page) => ({ ...page, type: types.find((type) => type.id === page.type), lang: languages.find((lang) => lang.id === page.languageId).name.toUpperCase() }));
      // const pagesWithTypesAndLanguages = pages.map((page) => ({ ...page, type: types.find((type) => type.id === page.type), lang: languages.find((lang) => lang.id === page.lang).name.toUpperCase() }));

      setLanguages(languages);
      setPagesCount(count);
      setTypes(types);
      setpagesData(pagesWithTypesAndLanguages);
    }

    getInitialData().then(() => setIsLoading(false));
  }, []);

  async function applyFilters(values) {
    const newParams = createSearchParams(searchParams);
    if (values.types.length > 0) {
      newParams.set("type", values.types.join(","));
    }

    if (values.languages.length > 0) {
      newParams.set("language", values.languages.join(","));
    }

    if (values.name.length > 0) {
      newParams.set("name", values.name);
    }

    setSearchParams(newParams);
    setActivePage(1);
  }

  const columns = useMemo(() => (
    [
      {
        Header: "Name", accessor: "name", width: "30%", align: "left",
      },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Language", accessor: "language", align: "center" },
      { Header: "Create at", accessor: "createdAt", align: "center" },
      { Header: "Update at", accessor: "updatedAt", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ]
  ), []);

  function handleDeletingPage(id) {
    if (deletingError) {
      setDeletingError(false);
    }

    setActiveModalName("deleting");
    setPageToDeleteId(id);
  }

  const rows = useMemo(() => {
    if (pagesData) {
      return (
        pagesData.map((page) => (
          {
            name: (
              <MDTypography component="span" variant="button" color="text" fontWeight="medium">
                {page.name}
              </MDTypography>
            ),
            type: (
              <MDTypography component="span" href="#" variant="button" color="text" fontWeight="medium">
                {page.type}
              </MDTypography>
            ),
            language: (
              <MDTypography component="span" href="#" variant="caption" color="text" fontWeight="medium">
                {page.language}
              </MDTypography>
            ),
            createdAt: format(new Date(page.createdAt), "kk:mm:ss dd.MM.yyyy"),
            updatedAt: format(new Date(page.updatedAt), "kk:mm:ss dd.MM.yyyy"),
            action: (
              <RowMenu id={page.id} handleDelete={handleDeletingPage} />
            ),
          }
        ))
      );
    }
  }, [pagesData]);

  return (
    <PagesPageStyles>
      {/* {!isLoading && types && languages && <PagesPageFilters types={types} languages={languages} handleClose={() => setFiltersOpened(false)} opened={filtersOpened} onApply={applyFilters} params={searchParams} />} */}
      <DashboardLayout>
        {activeModalName === "deleting" && (
          <Modal
            open={activeModalName === "deleting"}
            onClose={() => setActiveModalName(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm deleting
                {" "}
                {pagesData.find((page) => page.id === pageToDeleteId).name}
                {" "}
                page.
              </Typography>

              <Box display="grid" sx={{ gap: "10px" }}>
                <Grid gridColumn={1}>
                  <MDButton fullWidth onClick={() => setActiveModalName(null)} variant="contained" color="info">
                    Cancel
                  </MDButton>
                </Grid>
                <Grid gridColumn={2}>
                  <MDButton
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={() => {
                      workWithResponse(() => Api.deletePage(pageToDeleteId)).then((res) => {
                        if (res.data && !res.error) {
                          getPages();
                          setActiveModalName(null);
                        }
                      });
                    }}
                  >
                    Delete
                  </MDButton>
                </Grid>
              </Box>

              {deletingError && (
                <MDTypography component="span" href="#" variant="caption" color="error" fontWeight="medium">
                  *An error occurred while deleting
                </MDTypography>
              )}
            </Box>
          </Modal>
        )}

        <DashboardNavbar propsRoute={[""]} propsTitle="Pages" />
        <div className="content">
          {!isLoading && pagesData ? (
            <div className="tableWrapper">

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <div className="tableHeader">
                        <MDTypography variant="h6" color="white">
                          Pages
                        </MDTypography>

                        <div className="tableHeaderButtonsWrapper">
                          {/* <IconButton onClick={() => setFiltersOpened(true)}> */}
                          {/*  <Icon className="filtersButtonIcon">tune</Icon> */}
                          {/* </IconButton> */}

                          <Link to="/pages/create">
                            <Button variant="contained" color="info">
                              <MDTypography variant="button" color="info" fontWeight="bold">
                                Add new
                              </MDTypography>
                            </Button>
                          </Link>
                        </div>

                        {/* <Link to="/pages/create" className="button"> */}
                        {/* <MDTypography variant="Button" color="white" fontWeight="regular"> */}
                        {/*  Add new */}
                        {/* </MDTypography> */}

                        {/*  <AddCircleOutlineIcon /> */}
                        {/* </Link> */}
                      </div>

                    </MDBox>

                    <MDBox pt={3}>
                      {pagesCount > 0 ? (
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage
                          showTotalEntries
                          noEndBorder
                          pagination
                          activePage={activePage}
                          pagesCount={pagesCount}
                          setActivePage={setActivePage}
                          countOnPage={10}
                        />
                      ) : (
                        <MDBox variant="" fontWeight="regular" color="error" sx={{ textAlign: "center", fontSize: "18px", marginBottom: "12px" }}>
                          No pages were found for your request
                        </MDBox>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="loaderWrapper">
              <HashLoader color="#49a3f1" />
            </div>
          )}
        </div>
      </DashboardLayout>
    </PagesPageStyles>
  );
}

export default PagesPage;
