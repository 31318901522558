import React, { useEffect, useState } from "react";
import MDPagination from "../MDPagination";
import { Icon, IconButton, Pagination } from "@mui/material";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";

function CustomPagination({
  total, setActivePage, countOnPage = 10, activeIndex = 1,
}) {
  const [pagesCount, setPagesCount] = useState(0);

  useEffect(() => {
    setPagesCount(Math.ceil(total / countOnPage));
  }, []);

  const handleChange = (event, value) => {
    setActivePage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Pagination count={pagesCount} onChange={handleChange} page={activeIndex} variant="outlined" color="primary" />
    </ThemeProvider>
  );
}

export default CustomPagination;
