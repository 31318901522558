import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../api/api";

const initialState = {
  data: null,
  loggedIn: false,
  status: "idle",
  statusCode: 200,
  message: null,
};

export const loginUser = createAsyncThunk(
  "users/login",
  async (data, thunkAPI) => {
    try {
      const response = await Api.login(data.userData);

      console.log(response);

      if (response.statusCode === 200) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("access_token_expired_at", response.data.access_token_expired_at.toString());
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("refresh_token_expired_at", response.data.refresh_token_expired_at.toString());
        localStorage.setItem("logged_in", "true");
        return;
      }

      if (response.statusCode !== 200) {
        data.errorHandler();
      }

      return thunkAPI.rejectWithValue("");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error", e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const changeUserData = createAsyncThunk(
  "users/changeData",
  async (data, thunkAPI) => {
    try {
      const response = await Api.changeUserData(data);
      return response.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error", e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeUser: (state, action) => ({ ...state, data: action.payload, loggedIn: false }),
    setUser: (state, action) => ({ ...state, data: action.payload }),
    resetMessage: (state, action) => ({ ...state, message: null }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.loggedIn = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.loggedIn = false;
      })
      .addCase(changeUserData.fulfilled, (state, action) => {
        state.message = action.payload.message;

        if (!action.payload) {
          state.loggedIn = false;
        }
      });
  },
});

const { actions, reducer } = userSlice;
// Extract and export each action creator by name
export const { changeUser, setUser, resetMessage } = actions;
// Export the reducer, either as a default or named export
export default reducer;
