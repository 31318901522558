import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { PageStyles } from "./PageStyles";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Api from "../../api/api";
import { HashLoader } from "react-spinners";
import MDInput from "../../components/MDInput";
import CustomSelect from "../../components/form/customSelect/CustomSelect";
import TextEditor from "../../components/form/textEditor/TextEditor";
import Button from "@mui/material/Button";
import MDTypography from "../../components/MDTypography";
import UploadButton from "../../components/form/uploadButton/UploadButton";
import { Box, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDButton from "../../components/MDButton";
import CheckBox from "../../components/form/checkBox/CheckBox";
import { transliterate } from "transliteration";
import workWithResponse from "../../functions/workWithResponse";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  display: "grid",
  gap: "24px",
};

function Page() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoadingUploadButtons, setIsLoadingUploadButtons] = useState([]);
  const [afterSaveMainImageModalOpened, setAfterSaveMainImageModalOpened] = useState(false);
  const [afterSubmitModalTypeActive, setAfterSaveModalTypeActive] = useState("");
  const [types, setTypes] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const [submittingError, setSubmittingError] = useState(false);
  const [uploadImageButtonsWithError, setUploadImageButtonsWithError] = useState([]);

  const formRef = useRef({ current: {} });

  useEffect(() => {
    async function getInitialData() {
      const types = await workWithResponse(() => Api.getTypes()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      const languages = await workWithResponse(() => Api.getLanguages()).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }
      });

      setLanguages(languages);
      setTypes(types);

      if (id) {
        const pageDataFromServer = await workWithResponse(() => Api.getPage(id)).then((res) => {
          if (!res.error && res.data) {
            return res.data;
          }
        });

        setInitialValues({
          name: pageDataFromServer.name,
          languageID: pageDataFromServer.languageID,
          type: pageDataFromServer.type,
          slug: pageDataFromServer.slug,
          metaTitle: pageDataFromServer.metaTitle,
          metaDescription: pageDataFromServer.metaDescription,
          ogTitle: pageDataFromServer.ogTitle ? pageDataFromServer.ogTitle : "",
          ogDescription: pageDataFromServer.ogDescription ? pageDataFromServer.ogDescription : "",
          keywords: pageDataFromServer.keywords ? pageDataFromServer.keywords : "",
          noIndex: pageDataFromServer.noIndex,
          content: pageDataFromServer.content,
          contentTitle: pageDataFromServer.contentTitle,
          previewDescription: pageDataFromServer.previewDescription,
          mainImage: pageDataFromServer.mainImage,
          coverImage: pageDataFromServer.coverImage,
        });
      }
    }

    if (!id) {
      setInitialValues(
        {
          slug: "",
          name: "",
          languageID: "",
          type: "",

          metaTitle: "",
          metaDescription: "",
          ogTitle: "",
          ogDescription: "",
          keywords: "",
          noIndex: false,

          content: "",
          contentTitle: "",
          previewDescription: "",
          mainImage: undefined,
          coverImage: undefined,
        },
      );

      getInitialData().then(() => setIsLoading(false));
    } else if (id) {
      getInitialData().then(() => setIsLoading(false));
    }
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("* Required"),
    languageID: Yup.number().required("* Required"),
    type: Yup.number().required("* Required"),
    slug: Yup.string(),
    metaTitle: Yup.string().required("* Required"),
    metaDescription: Yup.string().required("* Required"),
    ogTitle: Yup.string(),
    ogDescription: Yup.string(),
    keywords: Yup.string(),
    noIndex: Yup.boolean(),
    content: Yup.string().required("* Required"),
    contentTitle: Yup.string().required("* Required"),
    previewDescription: Yup.string().required("* Required"),
    mainImage: Yup.string(),
    coverImage: Yup.string(),
  });

  // function getErrorMessage(key) {
  //   return typeof errorMessage === "object"
  //     ? errorMessage[key]
  //     && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
  //       key
  //     ].substring(1)}`
  //     : undefined;
  // }

  function onSubmit(
    values,
    { setSubmitting },
  ) {
    const pageData = {
      name: values.name,
      languageID: values.languageID,
      type: values.type,
      metaTitle: values.metaTitle,
      metaDescription: values.metaDescription,
      ogTitle: values.ogTitle.length > 0 ? values.ogTitle : undefined,
      ogDescription: values.ogDescription.length > 0 ? values.ogDescription : undefined,
      keywords: values.keywords.length > 0 ? values.keywords : undefined,
      noIndex: values.noIndex,
      content: values.content,
      contentTitle: values.contentTitle,
      previewDescription: values.previewDescription,
      mainImage: values.mainImage,
      coverImage: values.coverImage,
    };

    if (!id) {
      pageData.slug = values.slug.length === 0 ? transliterate(values.contentTitle.split(" ").join("-").toLowerCase()) : values.slug;
    }

    if (id) {
      workWithResponse(() => Api.updatePage(id, pageData)).then((res) => {
        if (!res.error && res.data) {
          setAfterSaveModalTypeActive("update");
        } else {
          setSubmittingError(true);
        }
      }).catch((e) => {
        console.warn(e);
      });
    } else {
      workWithResponse(() => Api.createPage(pageData)).then((res) => {
        if (!res.error && res.data) {
          setAfterSaveModalTypeActive("create");
        } else {
          setSubmittingError(true);
        }
      }).catch((e) => {
        console.warn(e);
      });
    }

    setSubmitting(false);
  }

  function handleKeyUp(key, error) {
    setErrorMessage(typeof errorMessage === "object" ? { ...errorMessage, [key]: error } : errorMessage);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }) => {
    const handleUploadFile = async (file, buttonName, fieldName) => {
      setIsLoadingUploadButtons([...isLoadingUploadButtons, buttonName]);
      workWithResponse(() => Api.createFile({ file })).then((res) => {
        if (!res.error && res.data) {
          // eslint-disable-next-line no-unused-expressions
          if (uploadImageButtonsWithError.includes(buttonName)) {
            setUploadImageButtonsWithError(uploadImageButtonsWithError.filter((errorItemName) => errorItemName !== buttonName));
          }

          return (
            setFieldValue(fieldName, res.data.url)
          );
        }
        setUploadImageButtonsWithError([...uploadImageButtonsWithError, buttonName]);
      })
        .finally(() => setIsLoadingUploadButtons([...isLoadingUploadButtons.filter((name) => name !== buttonName)]));
    };

    return (
      <>
        {values.mainImage && (
          <Modal
            open={afterSaveMainImageModalOpened}
            onClose={() => setAfterSaveMainImageModalOpened(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Do you want to use this image for the cover?
              </Typography>

              <img src={values.mainImage} alt="" style={{ width: "100%", objectFit: "cover", maxHeight: "400px" }} />

              <Box display="grid" sx={{ gap: "10px" }}>
                <Grid gridColumn={1}>
                  <MDButton fullWidth onClick={() => setAfterSaveMainImageModalOpened(false)} variant="contained" color="error">
                    Cancel
                  </MDButton>
                </Grid>
                <Grid gridColumn={2}>
                  <MDButton
                    fullWidth
                    variant="contained"
                    color="info"
                    onClick={() => {
                      setFieldValue("coverImage", values.mainImage);
                      setAfterSaveMainImageModalOpened(false);
                    }}
                  >
                    Confirm
                  </MDButton>
                </Grid>
              </Box>
            </Box>
          </Modal>
        )}

        <Form>
          <div className="formContent">
            <div className="formFieldsWrapper">
              <div className="formFields">
                <div className="fields">
                  <MDInput
                    name="name"
                    type="text"
                    label="Name"
                    showError
                    value={values.name}
                    error={errors.name && touched.name}
                    errorMessage={errors.name}
                    success={values.name.length > 0}
                    onChange={handleChange}
                    inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                  />
                  <MDInput
                    name="slug"
                    type="text"
                    label="URL"
                    showError
                    error={errors.slug && touched.slug}
                    errorMessage={errors.slug}
                    success={values.slug.length > 0}
                    value={values.slug}
                    onChange={handleChange}
                    inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                    tooltip="Leave this field empty to generate the URL automatically."
                    disabled={!!id}
                  />
                  <CustomSelect
                    optionTextKey="name"
                    valueKey="id"
                    label="Type of page"
                    name="type"
                    placeholder="Select type of page"
                    showError
                    error={errors.type && touched.type}
                    errorMessage={errors.type}
                    value={values.type}
                    options={types}
                    onChange={(value) => setFieldValue("type", value)}
                  />

                  <CustomSelect
                    optionTextKey="name"
                    valueKey="id"
                    label="Page language"
                    placeholder="Select page language"
                    name="languageId"
                    showError
                    error={errors.languageID && touched.languageID}
                    errorMessage={errors.languageID}
                    value={values.languageID}
                    options={languages}
                    onChange={(value) => setFieldValue("languageID", value)}
                  />
                </div>

                <div className="fieldsWrapper">
                  <div className="fieldsContent">
                    <MDTypography component="p" href="#" variant="caption" color="dark" fontWeight="bold" sx={{ marginBottom: "12px" }}>
                      Meta data
                    </MDTypography>

                    <div className="metaFieldsWrapper">
                      <div className="fields metaFields">
                        <MDInput
                          name="metaTitle"
                          type="text"
                          label="Meta title"
                          showError
                          error={errors.metaTitle && touched.metaTitle}
                          errorMessage={errors.metaTitle}
                          success={values.metaTitle.length > 0}
                          value={values.metaTitle}
                          onChange={handleChange}
                          inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                          multiline
                          rows={4}
                        />
                        <MDInput
                          name="metaDescription"
                          type="text"
                          label="Meta description"
                          showError
                          error={errors.metaDescription && touched.metaDescription}
                          errorMessage={errors.metaDescription}
                          success={values.metaDescription.length > 0}
                          value={values.metaDescription}
                          onChange={handleChange}
                          inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                          multiline
                          rows={4}
                        />

                        <MDInput
                          name="ogTitle"
                          type="text"
                          label="OG title"
                          showError
                          error={errors.ogTitle && touched.ogTitle}
                          errorMessage={errors.ogTitle}
                          success={values.ogTitle.length > 0}
                          value={values.ogTitle}
                          onChange={handleChange}
                          inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                          multiline
                          rows={4}
                        />

                        <MDInput
                          name="ogDescription"
                          type="text"
                          label="OG description"
                          showError
                          error={errors.ogDescription && touched.ogDescription}
                          errorMessage={errors.ogDescription}
                          success={values.ogDescription.length > 0}
                          value={values.ogDescription}
                          onChange={handleChange}
                          inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                          multiline
                          rows={4}
                        />

                        <MDInput
                          name="keywords"
                          type="text"
                          label="Meta keywords"
                          showError
                          error={errors.keywords && touched.keywords}
                          errorMessage={errors.keywords}
                          success={values.keywords.length > 0}
                          value={values.keywords}
                          onChange={handleChange}
                          inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        />
                      </div>

                      <div>
                        <CheckBox checked={values.noIndex} onChange={() => setFieldValue("noIndex", !values.noIndex)} label="Do not index the page" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldsWrapper">
                  <div>
                    <MDTypography component="p" variant="caption" color="dark" fontWeight="bold" sx={{ marginBottom: "12px" }}>
                      Content
                    </MDTypography>

                    <div className="fields">
                      <MDInput
                        name="contentTitle"
                        type="text"
                        label="Content title"
                        showError
                        error={errors.contentTitle && touched.contentTitle}
                        errorMessage={errors.contentTitle}
                        success={values.contentTitle.length > 0}
                        value={values.contentTitle}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />
                      <MDInput
                        name="previewDescription"
                        type="text"
                        label="Preview description"
                        showError
                        error={errors.previewDescription && touched.previewDescription}
                        errorMessage={errors.previewDescription}
                        success={values.previewDescription && values.previewDescription.length > 0}
                        value={values.previewDescription}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />
                    </div>
                  </div>

                  <div>
                    <UploadButton
                      onChangeFile={(file) => {
                        handleUploadFile(file, "main image", "mainImage")
                          .then(() => {
                            setAfterSaveMainImageModalOpened(true);
                          });
                      }}
                      isLoading={isLoadingUploadButtons.includes("main image")}
                      error={uploadImageButtonsWithError.includes("main image")}
                      label="Main image"
                      text={values.mainImage ? values.mainImage : undefined}
                      isTextALink
                    />
                  </div>

                  <div>
                    <UploadButton
                      onChangeFile={(file) => {
                        handleUploadFile(file, "cover image", "coverImage");
                      }}
                      isLoading={isLoadingUploadButtons.includes("cover image")}
                      error={uploadImageButtonsWithError.includes("cover image")}
                      label="Cover image"
                      text={values.coverImage ? values.coverImage : undefined}
                      isTextALink
                    />
                  </div>
                </div>

              </div>
              <div>
                <TextEditor
                  name="content"
                  value={values.content}
                  onChange={setFieldValue}
                  errorMessage={errors.content}
                  withMenuBar
                  onKeyUp={() => handleKeyUp("content")}
                  error={errors.content && touched.content}
                  extraBlockStyles={{ maxWidth: "832px", width: "100%" }}
                />
              </div>
            </div>

            <div className="submitButtonContainer">
              <Button type="submit" variant="contained" color={submittingError ? "error" : "primary"} sx={{ width: 400, margin: "auto", color: "#FFF" }}>Submit</Button>
              {submittingError && (
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  An error occurred while saving data
                </MDTypography>
              )}
            </div>
          </div>
        </Form>
      </>
    );
  };

  return (
    <PageStyles>
      <DashboardLayout>
        <DashboardNavbar propsRoute={[{ name: "pages", path: "pages/1" }, ""]} propsTitle={id && initialValues ? `Editing ${initialValues.name} page` : "New page"} />

        <Modal
          open={afterSubmitModalTypeActive === "create"}
          onClose={() => setAfterSaveModalTypeActive("")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              The page has been successfully created
            </Typography>

            <Box display="grid">
              <Grid gridColumn={1}>
                <MDButton fullWidth onClick={() => navigate("/pages")} variant="contained" color="info">
                  OK
                </MDButton>
              </Grid>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={afterSubmitModalTypeActive === "update"}
          onClose={() => setAfterSaveModalTypeActive("")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              The page has been successfully updated
            </Typography>

            <Box display="grid">
              <Grid gridColumn={1}>
                <MDButton fullWidth onClick={() => navigate("/pages")} variant="contained" color="info">
                  OK
                </MDButton>
              </Grid>
            </Box>
          </Box>
        </Modal>

        {isLoading ? (
          <div className="loaderWrapper">
            <HashLoader color="#49a3f1" />
          </div>
        ) : (
          <div className="content">
            {initialValues && (
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {renderForm}
              </Formik>
            )}
          </div>
        )}
      </DashboardLayout>
    </PageStyles>
  );
}

export default Page;
