import React from "react";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { Box, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MultipleSelect from "../../components/form/multipleSelect/MultipleSelect";
import Button from "@mui/material/Button";
import MDTypography from "../../components/MDTypography";
import { HashLoader } from "react-spinners";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { DashboardStyles } from "./DashboardStyles";
import routes from "../../routes";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <DashboardStyles>
      <DashboardLayout>
        <div className="content">
          {
            routes.map((route) => (
              <Link key={route.key} to={route.path}>
                {route.icon}
                {route.name}
              </Link>
            ))
          }
        </div>
      </DashboardLayout>
    </DashboardStyles>
  );
}

export default Dashboard;
