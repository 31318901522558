import styled from "styled-components";

export const PagesPageFiltersStyles = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  
  transform: translateX(calc(100% + 24px));
  transition: 0.4s ease-in transform;
  
  max-width: 400px;
  width: 100%;
  min-height: calc(100vh - 48px);
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  margin: 24px 24px 0 24px;
  background-color: #fff;
  z-index: 1101;
  border-radius: 0.75rem;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
  
  &.opened {
    transform: translateX(0);
  }
  
  .filtersContent {
    width: 100%;
  }
  
  .closeButton {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 20px;
  }
  
  .fieldsWrapper {
    display: grid;
  }
`;
